import { defineComponent, ref, watch } from '@vue/composition-api';
import vClickOutside from 'v-click-outside';
import { Portal } from 'portal-vue';
export default defineComponent({
    directives: {
        clickOutside: vClickOutside.directive
    },
    components: { Portal },
    props: {
        label: {
            type: [String, Object],
            default: ''
        },
        active: {
            type: Boolean,
            default: false
        },
        buttonDirectAction: {
            type: Boolean,
            default: false
        },
        count: {
            type: [Number, String],
            default: 0
        },
        disabled: {
            type: Boolean,
            default: false
        },
        iconClassName: {
            type: String,
            default: ''
        },
        isClickOutsideActive: {
            type: Boolean,
            default: true
        },
        toggle: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const isVisible = ref(props.toggle ? props.active : false);
        const form = ref({});
        const open = () => {
            isVisible.value = true;
        };
        watch(() => props.active, (newValue, oldValue) => {
            if (newValue !== oldValue && props.buttonDirectAction) {
                isVisible.value = newValue;
            }
        });
        const close = () => {
            isVisible.value = false;
        };
        const trigger = () => {
            isVisible.value = !isVisible.value;
            emit('visibility', isVisible.value);
        };
        const onClickOutside = () => {
            if (props.isClickOutsideActive) {
                close();
                emit('resetButtonValue');
            }
        };
        return {
            form,
            isVisible,
            close,
            onClickOutside,
            open,
            trigger
        };
    }
});
